import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import menuStylesImage from './assets/images/menu-styles.png';

const MenuStyles = () => {
    return (
        <section className="menu-styles py-5">
            <Container>
                <Row className="align-items-center">
                    <Col lg={6}>
                        <div className="menu-styles-content">
                            <h2 className="mb-3">Choose Your Perfect Menu Style</h2>
                            <p className="mb-3">We offer 3 versatile menu styles to suit your restaurant's unique needs:</p>
                            <ul className="menu-styles-list">
                                <li><i className="bi bi-check-circle me-2"></i>Text-based for a classic look</li>
                                <li><i className="bi bi-check-circle me-2"></i>Grid layout for visual appeal</li>
                                <li><i className="bi bi-check-circle me-2"></i>Wide view for easy navigation</li>
                            </ul>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <img src={menuStylesImage} alt="Menuhat Menu Styles" className="img-fluid" />
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default MenuStyles;