import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import wafaachef from './assets/images/wafaachef.png';
import mbarkeh from './assets/images/mbarkeh.jpg';
import rigs from './assets/images/rigs.jpg';
import saj from './assets/images/saj.png';
import oishi from './assets/images/oishi.png';

const clients = [
    { name: 'Chef Wafaa Kitchen', logo: wafaachef, link: 'https://public.menuhat.website/?menuId=392db0d8-5da3-4d55-be27-72b7423f7420' },
    { name: 'Majbous Al Mubarikya', logo: mbarkeh, link: 'https://public.menuhat.website/?menuId=f67aedbc-50cc-4a01-8bc8-01c82e243563' },
    { name: 'Rics Kitchen', logo: rigs, link: 'https://public.menuhat.website/?menuId=1377313e-f8cf-4237-b739-4c72ee65d544' },
    { name: 'Saj Botique', logo: saj, link: 'https://public.menuhat.website/?menuId=708afed1-3d32-42a8-b52d-4da6119a1049' },
    { name: 'Oishi Sushi', logo: oishi, link: 'https://public.menuhat.website/?menuId=d41fb763-0ea4-44e9-ac5b-fc277406b6d5' },
];

const Clients = () => {
    return (
        <section className="clients py-5">
            <Container>
                <h2 className="text-center mb-5">Our Clients</h2>
                <Row className="justify-content-center align-items-center">
                    {clients.map((client, index) => (
                        <Col key={index} xs={6} md={4} lg={2} className="mb-4 text-center">
                            <a href={client.link} target="_blank" rel="noopener noreferrer">
                                <img src={client.logo} alt={client.name} className="img-fluid client-logo" />
                            </a>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
};

export default Clients;