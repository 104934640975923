import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

const WhatsAppButton = () => {
    const phoneNumber = '971527212756'; // Replace with your actual WhatsApp number
    const message = 'Hello, I have a question about Menuhat.'; // Optional pre-filled message

    const handleClick = () => {
        const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(url, '_blank');
    };

    return (
        <div className="whatsapp-button" onClick={handleClick}>
            <FaWhatsapp size={30} />
        </div>
    );
};

export default WhatsAppButton;