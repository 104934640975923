import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/LandingPage.css';

import Header from './Header';
import Features from './Features';
import FeaturesInDeep from './FeaturesInDeep';
import Pricing from './Pricing';
import Support from './Support';
import Clients from './Clients';
import Footer from './Footer';
import MenuStyles from './MenuStyles';

const LandingPage = () => {
    return (
        <div className="landing-page">
            <Header />
            <Features />
            <MenuStyles />
            <FeaturesInDeep />
            <Pricing />
            <Support />
            <Clients />
            <Footer />
        </div>
    );
};

export default LandingPage;