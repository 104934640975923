import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import qrcodethumbnail from './assets/images/qrcodethumbnail.png';
import posthumbnail from './assets/images/posthumbnail.png';
import langthumbnail from './assets/images/langthumbnail.png';
import './assets/css/LandingPage.css';

const FeatureRow = ({ title, description, bulletPoints, image, imageOnRight }) => (
    <Row className="features align-items-center my-5">
        {!imageOnRight && (
            <Col md={6}>
                <img id="lang" src={image} alt={title} className="img-fluid" />
            </Col>
        )}
        <Col md={6}>
            <div className="feature-content">
                <h2>{title}</h2>
                <p>{description}</p>
                <ul className="feature-list">
                    {bulletPoints.map((point, index) => (
                        <li key={index}>
                            <i className="bi bi-check-circle me-2"></i> {point}
                        </li>
                    ))}
                </ul>
            </div>
        </Col>
        {imageOnRight && (
            <Col md={6}>
                <img src={image} alt={title} className="img-fluid" />
            </Col>
        )}
    </Row>
);

const Features = () => {
    const features = [
        {
            title: "QR Code Menu Generator",
            description: "Easily craft digital menus with QR codes, empowering restaurants to showcase their offerings and streamline ordering processes for patrons.",
            bulletPoints: [
                "This feature allows restaurants to create digital menus in the form of QR codes.",
                "Restaurants can input menu items, descriptions, prices, etc.",
                "The system generates a QR code for each table or the entire restaurant."
            ],
            image: qrcodethumbnail,
            imageOnRight: true
        },
        {
            title: "Streamlined Restaurant POS Integration",
            description: "Seamlessly integrate our system with your restaurant's POS, streamlining operations for enhanced efficiency.",
            bulletPoints: [
                "Sync orders directly from POS to streamline order management.",
                "Access real-time sales data for informed decision-making.",
                "Simplify inventory management by syncing with POS inventory levels."
            ],
            image: posthumbnail,
            imageOnRight: true
        },
        {
            title: "Multi-Language Menu Solution",
            description: "Cater to diverse clientele effortlessly by presenting your menu in various languages, including Arabic and Hebrew, ensuring a welcoming experience for all patrons.",
            bulletPoints: [
                "Easily switch between languages to accommodate international guests.",
                "Tailor your menu to match the preferences of your diverse customer base.",
                "Effortlessly integrate multilingual capabilities into your existing menu system."
            ],
            image: langthumbnail,
            imageOnRight: false
        }
    ];

    return (
        <section className="features py-2">
            <Container>
                {features.map((feature, index) => (
                    <FeatureRow key={index} {...feature} />
                ))}
            </Container>
        </section>
    );
};

export default Features;