import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import {
    LayoutGrid,
    Users,
    LayoutDashboard,
    CreditCard,
    Ticket,
    Layers,
    Globe,
    Wallet,
    ShoppingCart,
    FileCode,
    Bell,
    BarChart,
    Smartphone,
    Settings,
    PieChart,
    QrCode
} from 'lucide-react';

const features = [
    { Icon: LayoutGrid, title: "Multiple Branches Options", description: "Expand seamlessly with Multiple Branches Options." },
    { Icon: Users, title: "Unlimited Role & Permission", description: "Customize access with Unlimited Role & Permission settings." },
    { Icon: LayoutDashboard, title: "Restaurant Point Of Sale", description: "Streamline operations with Restaurant Point Of Sale integration." },
    { Icon: CreditCard, title: "Easy Payment System", description: "Simplify transactions with an Easy Payment System." },
    { Icon: Ticket, title: "Coupon Functionality", description: "Boost sales with Coupon & Voucher Functionality." },
    { Icon: Layers, title: "Product Variations & Addons", description: "Enhance offerings with Product Variations & Addons." },
    { Icon: Globe, title: "Multi-Language Supported", description: "Reach a wider audience with Multi-Language Support." },
    { Icon: Wallet, title: "Multiple Payment Gateway", description: "Provide flexibility with Multiple Payment Gateway options." },
    { Icon: ShoppingCart, title: "POS & Online Order", description: "Sync POS & Online Orders effortlessly for seamless service." },
    { Icon: FileCode, title: "Unlimited Page Builder", description: "Tailor your platform with Unlimited Page Builder capabilities." },
    { Icon: Bell, title: "Push Notification", description: "Stay connected with customers through Push Notifications." },
    { Icon: BarChart, title: "Cookies and Social Analytics", description: "Optimize performance with Cookies and Social Analytics." },
    { Icon: Smartphone, title: "Fully App", description: "Manage your business across Android, iOS, and Web with our versatile app." },
    { Icon: Settings, title: "Powerful Admin Panel", description: "Manage efficiently with a Powerful Admin Panel." },
    { Icon: PieChart, title: "Rich Analytics & Reports", description: "Gain insights with Rich Analytics & Reports features." },
    { Icon: QrCode, title: "No App Required", description: "Scan the QR code with their phone's camera without downloading any app." },
];

const FeaturesInDeep = () => {
    return (
        <section id="features" className="qr-menu-solutions heading py-5">
            <Container>
                <h2 className="text-center mb-3">QR Menu Solutions</h2>
                <p className="text-center mb-5">Explore Menuhat for a cost-effective online QR menu packed with all essential features, ensuring a seamless dining experience.</p>
                <Row>
                    {features.map((feature, index) => (
                        <Col key={index} md={3} className="mb-4">
                            <Card className="feature-card h-100">
                                <Card.Body className="d-flex flex-column align-items-center">
                                    <feature.Icon size={48} className="mb-3" style={{ color: '#008CB7' }} />
                                    <Card.Title className="font-weight-bold text-center">{feature.title}</Card.Title>
                                    <Card.Text className="text-muted small text-center">{feature.description}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
};

export default FeaturesInDeep;