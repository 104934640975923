import React from 'react';
import { Container } from 'react-bootstrap';

const PrivacyPolicy = () => {
    return (
        <Container className="py-5">
            <h1>Privacy Policy</h1>
            <p>Last updated: September 30, 2024</p>
            <p>
                Menuhat ("we", "our", or "us") is committed to protecting your privacy. This Privacy Policy explains how your personal information is collected, used, and disclosed by Menuhat.
            </p>
            <h2>Information We Collect</h2>
            <p>
                We collect information you provide directly to us, such as when you create an account, use our services, or communicate with us. This may include:
            </p>
            <ul>
                <li>Name and contact information</li>
                <li>Payment information</li>
                <li>Restaurant details</li>
                <li>Menu information</li>
            </ul>
            {/* Add more sections as needed */}
            <h2>How We Use Your Information</h2>
            <p>
                We use the information we collect to provide, maintain, and improve our services, to process your transactions, and to communicate with you.
            </p>
            {/* Add more content as needed */}
        </Container>
    );
};

export default PrivacyPolicy;