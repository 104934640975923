import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import supportthumbnail from './assets/images/supportthumbnail.png'

const Support = () => {
    return (
        <section className="support heading py-5 text-white">
            <Container>
                <Row className="align-items-center">
                    <Col lg={6}>
                        <h2 className="mb-3">Effortless Support Ticket Creation</h2>
                        <p className="mb-4">
                            Simplify your workload with our intuitive support ticket system, available for
                            exploration with our Free plan encompassing all features.
                        </p>
                        <Button variant="light" size="lg">Get started for support</Button>
                    </Col>
                    <Col lg={6} className="mt-4 mt-lg-0">
                        <img src={supportthumbnail} alt="Support Illustration" className="img-fluid" />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Support