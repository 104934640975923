import React from 'react'
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

const Pricing = () => {
    return (
        <section id="pricing" className="heading pricing py-5">
            <Container>
                <h2 className="text-center mb-3">Installation Plan Options</h2>
                <p className="text-center mb-5">Choose from flexible installation plans tailored to your needs, ensuring smooth deployment of our services for your business.</p>
                <Row>
                    {[
                        {
                            name: 'Menuhat Basic',
                            price: '$14.99/m',
                            description: 'Perfect for takeaway and pickup-focused restaurants.',
                            color: 'primary',
                            features: [
                                'All Features Included',
                                'Up to 3 tablets',
                                '2 free months on Annual payment',
                            ]
                        },
                        {
                            name: 'Menuhat Super',
                            price: '$24.99/m',
                            description: 'Ideal for small and medium-sized restaurants.',
                            color: 'info',
                            features: [
                                'All Features Included',
                                'Up to 9 tablets',
                                '2 free months on Annual payment',
                            ]
                        },
                        {
                            name: 'Menuhat Value',
                            price: '$40.99/m',
                            description: 'Perfect for large restaurants and hotels.',
                            color: 'primary',
                            features: [
                                'All Features Included',
                                'Up to 15 tablets',
                                '2 free months on Annual payment',
                            ]
                        }
                    ].map((plan, index) => (
                        <Col key={index} md={4}>
                            <Card className={`text-left mb-4 ${index === 1 ? 'bg-primary text-white' : 'bg-white'}`}>
                                <Card.Body>
                                    <Card.Title className="price">{plan.price}</Card.Title>
                                    <Card.Subtitle className="package-name-title">{plan.name}</Card.Subtitle>
                                    <Card.Text className={`package-name-subtitle ${index === 1 ? 'text-white' : ''}`}>{plan.description}</Card.Text>
                                    <Button variant={index === 1 ? 'light' : 'primary'} size="lg" className="mb-4 border-0" href="https://manager.menuhat.website/register?lang=English"
                                        target="_blank">SUBSCRIBE</Button>
                                    <ul className="list-unstyled text-start">
                                        {plan.features.map((feature, fIndex) => (
                                            <li key={fIndex} className="mb-4">
                                                <i className={`bi ${fIndex < 5 ? 'bi-check-circle' : 'bi-x-circle'} me-2`}></i>
                                                {feature}
                                            </li>
                                        ))}
                                    </ul>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    )
}

export default Pricing