import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { FaGooglePlay, FaApple } from 'react-icons/fa';
import MenuhatLogo from './assets/images/logodefault.png'
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="footer bg-white py-5">
            <Container>
                <Row className="align-items-start">
                    <Col lg={4} className="mb-4 mb-lg-0">
                        <img src={MenuhatLogo} alt="Menuhat Logo" className="mb-3" style={{ width: '35%' }} />
                        <p className="text-muted small">
                            Effortlessly create dynamic menus and enable
                            contactless table ordering, seamlessly integrated with
                            your restaurant's POS system.
                        </p>
                        <div className="app-store-badges mt-3">
                            <a href="https://play.google.com/store/apps/details?id=com.menuhat"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-outline-dark me-2">
                                <FaGooglePlay className="me-2" />
                                Google Play
                            </a>
                            <a href="https://apps.apple.com/in/app/menuhat/id1669901550"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-outline-dark">
                                <FaApple className="me-2" />
                                App Store
                            </a>
                        </div>
                    </Col>
                    <Col lg={3} className="mb-4 mb-lg-0">
                        <h6 className="text-dark fw-bold">Quick Links</h6>
                        <ul className="list-unstyled">
                            <li><Link to="/privacy-policy" className="text-muted small" target="_blank"
                                rel="noopener noreferrer">Privacy Policy</Link></li>
                            <li><Link to="/terms-and-conditions" className="text-muted small" target="_blank"
                                rel="noopener noreferrer">Terms and Conditions</Link></li>
                        </ul>
                    </Col>
                    <Col lg={3} className="mb-4 mb-lg-0">
                        <h6 className="text-dark fw-bold">
                            <span className="text-danger me-2">📍</span>
                            Visit Us:
                        </h6>
                        <p className="text-muted small mb-0">Techbox Technology LLC.</p>
                        <p className="text-muted small mb-0">Sharjah Media City, Sharjah, UAE</p>
                    </Col>
                    <Col lg={2}>
                        <h6 className="text-dark fw-bold">
                            <span className="text-primary me-2">💬</span>
                            Contact Us:
                        </h6>
                        <p className="text-muted small mb-0">support@menuhat.website</p>
                        <p className="text-muted small">+971 52 7212 756</p>
                    </Col>
                </Row>
                <hr className="my-4" />
                <Row className="align-items-center">
                    <Col className="text-start">
                        <p className="text-muted small mb-0">
                            © Menuhat. All Right Reserved 2024.
                        </p>
                    </Col>
                    <Col className="text-end">
                        <div className="social-icons">
                            <a href="https://www.facebook.com/profile.php?id=100076846150758" className="text-muted me-3" target="_blank" rel="noopener noreferrer">
                                <i className="bi bi-facebook"></i>
                            </a>
                            <a href="https://www.instagram.com/menuhatapp/" className="text-muted me-3" target="_blank" rel="noopener noreferrer">
                                <i className="bi bi-instagram"></i>
                            </a>
                            <a href="https://www.youtube.com/channel/UCtX2JKxMY8D6E2xeVZa6_bw" className="text-muted me-3" target="_blank" rel="noopener noreferrer">
                                <i className="bi bi-youtube"></i>
                            </a>
                            <a href="https://www.linkedin.com/company/menuhat-application/" className="text-muted me-3" target="_blank" rel="noopener noreferrer">
                                <i className="bi bi-linkedin"></i>
                            </a>
                            <a href="https://x.com/MenuhatApp" className="text-muted" target="_blank" rel="noopener noreferrer">
                                <i className="bi bi-twitter"></i>
                            </a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer