import React from 'react'
import { Navbar, Nav, Container, Row, Col, Button } from 'react-bootstrap';
import logo from './assets/images/logowhite.png'
import hero from './assets/images/hero.png'

const Header = () => {
    return (
        <section id="home" className="hero bg-purple text-white">
            <Navbar expand="lg" className="py-3">
                <Container>
                    <Navbar.Brand href="#home"><img src={logo} alt="Menuhat Platform" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mx-auto">
                            <Nav.Link href="#home">Home</Nav.Link>
                            {/* <Nav.Link href="#about">About us</Nav.Link> */}
                            <Nav.Link href="#features">Features</Nav.Link>
                            <Nav.Link href="#pricing">Pricing</Nav.Link>
                            <Nav.Link href="https://menuhatdocs.netlify.app" target="_blank"
                                rel="noopener noreferrer">Documentation</Nav.Link>
                        </Nav>
                        <div className="d-flex justify-content-center">
                            {/* <Button variant="dark" className="me-2">Demo</Button> */}
                            <Button
                                variant="info"
                                href="https://manager.menuhat.website/login?lang=English"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Sign In
                            </Button>
                        </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <Container className="hero-content text-center py-5">
                <Row className="justify-content-center">
                    <Col lg={8}>
                        <h1 className="display-4 fw-bold mb-4">QR Code Restaurant Menu & Contactless Table Ordering System</h1>
                        <p className="lead mb-5">Effortlessly create dynamic menus and enable contactless table ordering, seamlessly integrated with your restaurant's POS system.</p>
                    </Col>
                </Row>
                <img src={hero} alt="Dashboard" className="img-fluid hero-image" />
            </Container>
        </section>
    )
}

export default Header